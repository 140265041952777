import React from 'react';

export const ArrowBigUpperRightSvg = () => (
  <svg
    className="w-full h-auto transform -rotate-90 opacity-50"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg">
    <path d="M20 20L19.8972 -8.69734e-07L12.7878 7.1092L12.8176 12.8142L7.11198 12.7844L-4.56052e-09 19.8957L20 20Z" />
  </svg>
);

export const ArrowSmallUpperLeftSvg = () => (
  <svg
    className="absolute top-0 left-0 w-12 h-12 transform rotate-180 md:w-16 md:h-16 text-yellow-500"
    width=" 20"
    height="20"
    viewBox="0 0 20 20"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg">
    <path d="M20 20L19.8972 -8.69734e-07L12.7878 7.1092L12.8176 12.8142L7.11198 12.7844L-4.56052e-09 19.8957L20 20Z" />
  </svg>
);

export const ArrowSmallUpperRightSvg = () => (
  <svg
    className="absolute top-0 right-0 w-12 h-12 transform -rotate-90 md:w-16 md:h-16 text-yellow-500"
    width=" 20"
    height="20"
    viewBox="0 0 20 20"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg">
    <path d="M20 20L19.8972 -8.69734e-07L12.7878 7.1092L12.8176 12.8142L7.11198 12.7844L-4.56052e-09 19.8957L20 20Z" />
  </svg>
);

export const ArrowSmallBottomLeftSvg = () => (
  <svg
    className="absolute bottom-0 left-0 w-12 h-12 transform rotate-90 md:w-16 md:h-16 text-yellow-500"
    width=" 20"
    height="20"
    viewBox="0 0 20 20"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg">
    <path d="M20 20L19.8972 -8.69734e-07L12.7878 7.1092L12.8176 12.8142L7.11198 12.7844L-4.56052e-09 19.8957L20 20Z" />
  </svg>
);

export const ArrowSmallBottomRightSvg = () => (
  <svg
    className="absolute bottom-0 right-0 w-12 h-12 transform rotate-0 md:w-16 md:h-16 text-yellow-500"
    width=" 20"
    height="20"
    viewBox="0 0 20 20"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg">
    <path d="M20 20L19.8972 -8.69734e-07L12.7878 7.1092L12.8176 12.8142L7.11198 12.7844L-4.56052e-09 19.8957L20 20Z" />
  </svg>
);

export const ReadDownArrow = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 22" width="25px">
    <defs />
    <path
      d="m345.44 248.29l-194.29 194.28c-12.359 12.365-32.397 12.365-44.75 0-12.354-12.354-12.354-32.391 0-44.744l171.91-171.91-171.91-171.9c-12.354-12.359-12.354-32.394 0-44.748 12.354-12.359 32.391-12.359 44.75 0l194.29 194.28c6.177 6.18 9.262 14.271 9.262 22.366 0 8.099-3.091 16.196-9.267 22.373"
      transform="matrix(.03541-.00013.00013.03541 2.98 3.02)"
      fill="rgba(234, 179, 8, 1)"
    />
  </svg>
);

export const HeroLogo = () => (
  <svg
    className="flex justify-betweenw ml-7 h-full pt-6 opacity-50 sm:h-[261px] text-[rgba(169,169,169,1)]"
    width="484"
    height="486"
    viewBox="0 0 484 486"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg">
    <path d="M484 162.721V220.095L460.946 243.001L484 265.911V323.284L403.212 243.001L484 162.721ZM242.007 81.1246L321.96 0H264.813L242.007 23.1496L219.195 0H162.049L242.007 81.1246ZM242.007 404.878L162.049 486H219.187L242.001 462.853L264.813 486H321.951L242.007 404.878ZM128.006 128.538L127.422 14.4065L87.0122 54.9747L87.1816 87.5301L54.7602 87.36L14.3443 127.943L128.006 128.538ZM355.994 357.453L356.578 471.582L396.979 431.014L396.81 398.458L429.231 398.628L469.635 358.06L355.994 357.453ZM80.7762 243.001L0 162.721V220.092L23.0543 243.001L0 265.911V323.284L80.7762 243.001ZM128.006 357.453L14.3472 358.04L54.7485 398.631L87.1699 398.461L87.0005 431.017L127.402 471.585L128.006 357.453ZM355.994 128.524L469.653 127.937L429.252 87.3718L396.83 87.5419L396.999 54.9864L356.587 14.4065L355.994 128.524Z" />
  </svg>
);

export const ArrowDownFooterContactForm = () => (
  <svg
    className="w-12 h-auto transform rotate-45 translate-y-1/4 arrow-down-footer-contact-form"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg">
    <path d="M20 20L19.8972 -8.69734e-07L12.7878 7.1092L12.8176 12.8142L7.11198 12.7844L-4.56052e-09 19.8957L20 20Z" />
  </svg>
);

export const GoogleMapsIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 92.3 132.3" width="30" height="30">
    <path
      fill="#1a73e8"
      d="M60.2 2.2C55.8.8 51 0 46.1 0 32 0 19.3 6.4 10.8 16.5l21.8 18.3L60.2 2.2z"
    />
    <path
      fill="#ea4335"
      d="M10.8 16.5C4.1 24.5 0 34.9 0 46.1c0 8.7 1.7 15.7 4.6 22l28-33.3-21.8-18.3z"
    />
    <path
      fill="#4285f4"
      d="M46.2 28.5c9.8 0 17.7 7.9 17.7 17.7 0 4.3-1.6 8.3-4.2 11.4 0 0 13.9-16.6 27.5-32.7-5.6-10.8-15.3-19-27-22.7L32.6 34.8c3.3-3.8 8.1-6.3 13.6-6.3"
    />
    <path
      fill="#fbbc04"
      d="M46.2 63.8c-9.8 0-17.7-7.9-17.7-17.7 0-4.3 1.5-8.3 4.1-11.3l-28 33.3c4.8 10.6 12.8 19.2 21 29.9l34.1-40.5c-3.3 3.9-8.1 6.3-13.5 6.3"
    />
    <path
      fill="#34a853"
      d="M59.1 109.2c15.4-24.1 33.3-35 33.3-63 0-7.7-1.9-14.9-5.2-21.3L25.6 98c2.6 3.4 5.3 7.3 7.9 11.3 9.4 14.5 6.8 23.1 12.8 23.1s3.4-8.7 12.8-23.2"
    />
  </svg>
);

export const CloseButton = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="38px" height="38px">
    <path d="M 12 2 C 6.4889971 2 2 6.4889971 2 12 C 2 17.511003 6.4889971 22 12 22 C 17.511003 22 22 17.511003 22 12 C 22 6.4889971 17.511003 2 12 2 z M 12 4 C 16.430123 4 20 7.5698774 20 12 C 20 16.430123 16.430123 20 12 20 C 7.5698774 20 4 16.430123 4 12 C 4 7.5698774 7.5698774 4 12 4 z M 8.7070312 7.2929688 L 7.2929688 8.7070312 L 10.585938 12 L 7.2929688 15.292969 L 8.7070312 16.707031 L 12 13.414062 L 15.292969 16.707031 L 16.707031 15.292969 L 13.414062 12 L 16.707031 8.7070312 L 15.292969 7.2929688 L 12 10.585938 L 8.7070312 7.2929688 z" />
  </svg>
);
