import PropTypes from 'prop-types';
import React from 'react';
import { Cookies, CookiesProvider } from 'react-cookie';
import EmailSignUp from '../components/EmailSignUp/EmailSignUp';
import featureFlags from '../featureFlags';
import Footer from './Footer';
import Header from './Header';

export default function Layout({ path, children }) {
  const cookies = new Cookies();
  const hasDisplayedEmailSignUp = cookies.get('emailSignUp');

  return (
    <CookiesProvider>
      <div className="site">
        <Header path={path} />
        <main className="site-content">{children}</main>
        {!hasDisplayedEmailSignUp && featureFlags.displayEmailSignUp ? <EmailSignUp /> : ''}
        <Footer />
      </div>
    </CookiesProvider>
  );
}

Layout.propTypes = {
  path: PropTypes.string,
  children: PropTypes.shape({}).isRequired
};

Layout.defaultProps = {
  path: ''
};
