import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import featureFlags from '../featureFlags';

export default function Header({ path }) {
  const [burgerMenuIsOpen, setBurgerMenuIsOpen] = useState(false);
  const [currentPath, setCurrentPath] = useState('');

  useEffect(() => {
    if (path) {
      setCurrentPath(path);
    }
  });

  return (
    <header className="fixed z-50 top-0 w-full m-auto bg-white py-4">
      <div className="flex max-w-5xl m-auto items-center justify-between px-6 md:px-2">
        <div className="pt-2">
          <Link to="/">
            <img
              src="https://res.cloudinary.com/doqoc06en/image/upload/v1656450659/sunrise-re/sunrise-logo_ae4mpa.svg"
              alt="Sunrise Real Estate Logo"
              width={169}
              height={53}
            />
          </Link>
        </div>

        <nav className="nav hidden space-x-8 px-6 md:flex">
          <Link to="/" className={currentPath === '/' ? 'active' : ''}>
            Home
          </Link>
          <Link to="/about" className={currentPath === '/about/' ? 'active' : ''}>
            About
          </Link>
          {featureFlags.displayEsgPageLink ? (
            <Link
              to="/sustainability"
              className={currentPath === '/sustainability/' ? 'active' : ''}>
              Sustainability
            </Link>
          ) : (
            ''
          )}
          <Link to="/team" className={currentPath === '/team/' ? 'active' : ''}>
            Our Team
          </Link>
          <Link to="/portfolio" className={currentPath === '/portfolio/' ? 'active' : ''}>
            Portfolio
          </Link>
          <Link to="/news" className={currentPath === '/news/' ? 'active' : ''}>
            News
          </Link>
          <Link to="/contact" className={currentPath === '/contact/' ? 'active' : ''}>
            Contact
          </Link>
        </nav>

        <button
          type="button"
          id="menu-btn"
          aria-label="burger menu"
          className={`${
            burgerMenuIsOpen ? 'open' : ''
          } block hamburger md:hidden focus:outline-none`}
          onClick={() => setBurgerMenuIsOpen((prevState) => !prevState)}>
          <span className="hamburger-top" />
          <span className="hamburger-middle" />
          <span className="hamburger-bottom" />
        </button>
      </div>
      <div className="md:hidden nav">
        <div
          id="menu"
          className={`${
            burgerMenuIsOpen ? 'menu-open' : 'menu-hidden'
          } menu fixed z-50 flex-col items-center self-end py-8 mt-10 space-y-6 font-bold bg-white sm:w-auto sm:self-center left-6 right-6 drop-shadow-md`}>
          <Link
            to="/"
            onClick={() => setBurgerMenuIsOpen(false)}
            className={path === '/' ? 'active' : ''}>
            Home
          </Link>
          <Link
            to="/about"
            onClick={() => setBurgerMenuIsOpen(false)}
            className={path === '/about/' ? 'active' : ''}>
            About
          </Link>
          {featureFlags.displayEsgPageLink ? (
            <Link
              to="/sustainability"
              onClick={() => setBurgerMenuIsOpen(false)}
              className={path === '/sustainability/' ? 'active' : ''}>
              Sustainability
            </Link>
          ) : (
            ''
          )}

          <Link
            to="/team"
            onClick={() => setBurgerMenuIsOpen(false)}
            className={path === '/team/' ? 'active' : ''}>
            Our Team
          </Link>
          <Link
            to="/portfolio"
            onClick={() => setBurgerMenuIsOpen(false)}
            className={path === '/portfolio/' ? 'active' : ''}>
            Portfolio
          </Link>
          <Link
            to="/news"
            onClick={() => setBurgerMenuIsOpen(false)}
            className={path === '/news/' ? 'active' : ''}>
            News
          </Link>
          <Link
            to="/contact"
            onClick={() => setBurgerMenuIsOpen(false)}
            className={path === '/contact/' ? 'active' : ''}>
            Contact
          </Link>
        </div>
      </div>
    </header>
  );
}

Header.propTypes = {
  path: PropTypes.string
};

Header.defaultProps = {
  path: ''
};
