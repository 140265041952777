import React, { useState } from 'react';
import { useCookies } from 'react-cookie';
import { useForm } from 'react-hook-form';
import { CloseButton } from '../../assets/svgs';
import copy from '../../copy/email-sign-up';

const Required = () => <span className="text-app-yellow">*</span>;

const EmailSignUp = () => {
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm();

  const [cookies, setCookie] = useCookies(['emailSignUp']);
  const [subscribed, setSubscribed] = useState(false);

  const onClose = () => {
    const expiryTime = 400 * 24 * 60 * 60; // 400 days
    const expires = new Date();
    expires.setTime(expires.getTime() + expiryTime);

    setCookie('emailSignUp', 'emailSignUpClosed', {
      path: '/',
      expires,
      maxAge: expiryTime
    });
  };

  const onSubmit = (data) => {
    const url = new URL('https://api.sender.net/v2/subscribers');

    const headers = {
      Authorization: `Bearer ${process.env.GATSBY_SENDER_API_KEY}`,
      'Content-Type': 'application/json',
      Accept: 'application/json'
    };

    fetch(url, {
      method: 'POST',
      headers,
      body: JSON.stringify(data)
    })
      .then((response) => {
        setSubscribed(true);
        setTimeout(onClose, 1000);
        response.json();
      })
      .catch((response) => {
        console.log(response.error);
      });
  };

  const { title, subTitle, button, error, thankYou } = copy;

  return (
    <div
      className={`
        ${cookies && cookies.emailSignUp === 'emailSignUpClosed' ? 'slide-out' : 'slide-in'} 
        fixed slider w-3/4 h-3/6 bottom-5 right-0 px-5 py-2 z-50 border rounded-md bg-white md:w-[500px] md:h-[230px]`}>
      <div onClick={onClose} role="presentation">
        <span className="absolute right-4 top-5 cursor-pointer">
          <CloseButton />
        </span>
      </div>
      <div className="mt-3">
        <div className="mr-10">
          <h1 className="text-xl font-bold md:text-4xl">{title}</h1>
          <h2 className="text-l italic md:text-xl">{subTitle}</h2>
        </div>
        <form className="mt-10" onSubmit={handleSubmit(onSubmit)}>
          <strong>
            email
            <Required />
          </strong>

          <div className="inline-block w-full">
            <label htmlFor="emailSignUp">
              <input
                id="emailSignUp"
                className="h-9 w-full md:w-3/4 px-3 py-2 border-2 border-block"
                {...register('email', {
                  required: true,
                  pattern: /^(.+)@(.+){2,}\.(.+){2,}$/
                })}
              />
            </label>
            <input
              type="submit"
              className="h-9 px-7 mt-3 float-left font-bold bg-app-yellow cursor-pointer hover:bg-app-yellow-muted text-app-black md:float-right md:mt-0"
              value={button}
            />
          </div>
          {errors.email && (
            <span role="alert" className="text-red-800 text-left">
              {error}
            </span>
          )}
        </form>
        <div
          className={`${
            subscribed ? '' : 'hidden'
          } absolute text-4xl h-full pt-[50%] w-full right-0 md:right-4 top-0 text-center mb-10 bg-white md:h-[226px] md:leading-[226px] md:w-[465px] md:pt-0`}>
          <h1 className="font-bold ">{thankYou}</h1>
        </div>
      </div>
    </div>
  );
};

export default EmailSignUp;
