import { Link } from 'gatsby';
import React, { useEffect, useState } from 'react';

export default function Footer() {
  const [clickEvent, setClickEvent] = useState(false);

  const handleClick = () => {
    const selector = '.iubenda-tp-btn.iubenda-cs-preferences-link';
    const iubendaPrivacyWidget = document.querySelector(selector);
    if (iubendaPrivacyWidget && iubendaPrivacyWidget instanceof HTMLElement) {
      iubendaPrivacyWidget.click();
    }
  };

  useEffect(() => {
    const selector = '.iubenda-tp-btn.iubenda-cs-preferences-link';
    const iubendaPrivacyWidget = document.querySelector(selector);
    if (iubendaPrivacyWidget) {
      setClickEvent(true);
    }
  }, []);

  return (
    <footer className="bg-background text-white text-sm">
      <div className="flex max-w-5xl m-auto py-6 px-0">
        <div className="flex flex-wrap space-y-4 w-full md:space-x-0 mx-4 md:mx-0">
          <div className="flex flex-wrap space-y-4 px-0 md:space-x-0 md:-mx-4 w-full md:w-1/2 md:px-4">
            <div className="w-full md:w-1/2 px-0">
              <img
                src="https://res.cloudinary.com/doqoc06en/image/upload/v1656450665/sunrise-re/sunrise-logo-white_vqvzg6.svg"
                alt="Sunrise Real Estate Logo"
                width={150}
                height={70}
              />
            </div>
            <div className="w-full md:w-1/2 px-2">
              <div className="flex flex-col space-y-2">
                <Link to="/terms-and-conditions">Terms and Conditions</Link>
                <Link to="/privacy-policy">Privacy Notice</Link>
                {clickEvent ? (
                  <div
                    role="button"
                    tabIndex={0}
                    className="cursor-pointer"
                    onClick={() => handleClick()}>
                    Cookies
                  </div>
                ) : null}
              </div>
            </div>
          </div>
          <div className="text-right md:w-1/2 px-2">
            <p className="mb-4">© 2020 Sunrise Real Estate Limited</p>
          </div>
        </div>
      </div>
    </footer>
  );
}
