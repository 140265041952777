module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Sunrise Real Estate","short_name":"Sunrise-re","start_url":"/","lang":"en","display":"standalone","icon":"src/images/sunrise-fav.png","cache_busting_mode":"none","legacy":true,"theme_color_in_head":true,"crossOrigin":"anonymous","include_favicon":true,"cacheDigest":null},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-K5QKHRP","includeInDevelopment":false,"enableWebVitalsTracking":true,"defaultDataLayer":null,"routeChangeEventName":"gatsby-route-change","selfHostedOrigin":"https://www.googletagmanager.com"},
    },{
      plugin: require('../node_modules/gatsby-plugin-iubenda-cookie-footer/gatsby-browser.js'),
      options: {"plugins":[],"googleTagManagerOptions":true,"iubendaOptions":{"siteId":"2734843","cookiePolicyId":"79784565","lang":"en","countryDetection":true,"cookiePolicyInOtherWindow":true,"askConsentAtCookiePolicyUpdate":true,"consentOnContinuedBrowsing":false,"perPurposeConsent":true,"whitelabel":false,"cookiePolicyUrl":"http://localhost:8000/privacy-policy","floatingPreferencesButtonDisplay":true,"banner":{"backgroundOverlay":true,"acceptButtonDisplay":true,"customizeButtonDisplay":true,"acceptButtonColor":"#FFD500","acceptButtonCaptionColor":"black","customizeButtonColor":"#a5a5a5","customizeButtonCaptionColor":"white","rejectButtonColor":"#0073CE","rejectButtonCaptionColor":"white","closeButtonDisplay":false,"position":"float-bottom-center","textColor":"#1c1c1c","backgroundColor":"#ffffff","customizeButtonCaption":"No, customise","cookiePolicyLinkCaption":"privacy notice","content":" <div id=\"iubenda-cs-title\">Notice</div> <div id=\"iubenda-cs-paragraph\">We require cookies to enable our website to function. We would also like to set analytics cookies to help us monitor how people use our website so that we can continue to improve it. For more information on the technology we use please visit our  <a href=\"https://sunrise-re.com/privacy-notice\" class=\"iubenda-cs-cookie-policy-lnk\" target=\"_blank\" rel=\"noopener\">privacy notice</a>.</div>"}}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
